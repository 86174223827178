<template>
    <div>
       <Slider
      :arrow="true"
      :options="options"
      @click="click"
      class="slider"
      :list="list"
      v-model="activeIndex"
    ></Slider>
    </div>
</template>

<script>
import Slider from "vue-piece-slider"
    export default {
        name:'Slide',
        data() {
            return {
            activeIndex: 0,
            list: [
                "/img/slides/1.jpg",
                "/img/slides/2.jpg",
                "/img/slides/3.jpg",
            ],
            options: {
                angle: 180,
                extraSpacing: { extraX: 1, extraY: 1 },
                piecesWidth: 100,
                ty: 150,
                piecesSpacing: 1,
                rotate:0,
                translate:0,
                animation: {
                duration: 2000,
                easing: "easeOutQuint"
                }
            }
            }
        },
        components: {
            Slider
        },
        methods: {
            click() {
                console.log("object")
            }
        }
    }
</script>

<style lang="scss" scoped>
.slider{
    padding-right:0;
}
</style>